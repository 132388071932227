import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/SEO";
import SplashTitle from "../components/SplashTitle";
import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import ContactUsBar from "../components/ContactUsBar";

export const pageQuery = graphql`
  {
    splashImage: file(relativePath: { eq: "city-birdseye.jpg" }) {
      ...SplashImage
    }
    site {
      siteMetadata {
        defaultLanguage
      }
    }
  }
`;

export default function AssetDataPage({ data }) {
  const intl = useIntl();

  return (
    <Layout
      intl={intl}
      title={intl.formatMessage({ id: "asset-data.title", defaultMessage: "Using Geospatial Intelligence to Close Information Gaps" })}
    >
      <Seo
        intl={intl}
        title={intl.formatMessage({ id: "asset-data.title", defaultMessage: "Using Geospatial Intelligence to Close Information Gaps" })}
      />
      <SplashTitle
        image={getImage(data.splashImage)}
        subheadline={intl.formatMessage({ id: "asset-data.subheadline", defaultMessage: "Incomplete Property Data" })}
      >
        <FormattedMessage id="asset-data.headline" defaultMessage="Using Geospatial Intelligence to Close Information Gaps" />
      </SplashTitle>
      <main>
        <div className="max-w-2xl lg:max-w-7xl mx-auto py-24 sm:px-2 sm:py-32 px-4 lg:px-8">
          <div className="grid grid-cols-1 items-center gap-y-4 md:gap-y-10 gap-x-16 lg:grid-cols-2">
            <h2 className="text-4xl md:text-5xl leading-tight md:leading-snug font-extrabold tracking-tight text-gray-900">
              {intl.formatMessage(
                { id: "asset-data.main-section-headline", defaultMessage: "<mark>Up-to-date</mark> and actionable property information" },
                { mark: (text) => <mark>{text}</mark> }
              )}
            </h2>
            <p className="mt-4 text-gray-500 prose-md md:prose-lg">
              <FormattedMessage
                id="asset-data.description"
                defaultMessage="In the ongoing digital transformation of the real estate sector the struggle of missing, wrong or outdated property data is real. With our Big Geodata enabled solutions we help our clients to get up-to-date and actionable property information like building footprints, built-up changes over time, environmental hazards, reachability or the presence of green roofs and solar panels. The sky is the limit. Literally."
              />
            </p>
          </div>
          <div className="w-full my-24">
            <h2 className="text-2xl mb-8 md:text-4xl md:mb-12 font-extrabold tracking-tight text-gray-900">
              <FormattedMessage id="asset-data.how-does-it-work" defaultMessage="How does it work?" />
            </h2>
            <div className="mt-4 grid grid-cols-1 gap-y-10 gap-x-8 lg:grid-cols-3">
              <div className="sm:flex lg:block">
                <div className="sm:flex-shrink-0">
                  <img className="w-16 h-16" src="/symbols/satellite.png" alt="" />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                  <h3 className="text-sm font-medium text-gray-900">
                    <FormattedMessage id="asset-data.section-1-headline-1" defaultMessage="Satellite Imagery" />
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    <FormattedMessage
                      id="asset-data.section-1-text-1"
                      defaultMessage="We collect and analyze multi-resolution satellite data to extract relevant information about properties with our dedicated, machine learning driven processing pipelines."
                    />
                  </p>
                </div>
              </div>
              <div className="sm:flex lg:block">
                <div className="sm:flex-shrink-0">
                  <img className="w-16 h-16" src="/symbols/ai-01.png" alt="" />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                  <h3 className="text-sm font-medium text-gray-900">
                    <FormattedMessage id="asset-data.section-1-headline-2" defaultMessage="Geodata Fusion" />
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    <FormattedMessage
                      id="asset-data.section-1-text-2"
                      defaultMessage="Wherever necessary, we integrate additional geodata and external services to enrich the information content."
                    />
                  </p>
                </div>
              </div>
              <div className="sm:flex lg:block">
                <div className="sm:flex-shrink-0">
                  <img className="w-16 h-16" src="/symbols/items.png" alt="" />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                  <h3 className="text-sm font-medium text-gray-900">
                    <FormattedMessage id="asset-data.section-1-headline-3" defaultMessage="Simple Service Implementation" />
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    <FormattedMessage
                      id="asset-data.section-1-text-3"
                      defaultMessage="Our clients get access to our building information extraction services either through an intuitive Web Portal or an API."
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full my-24"></div>
          <div>
            <h2 className="text-2xl mb-8 md:text-4xl md:mb-12 font-extrabold tracking-tight text-gray-900">
              <FormattedMessage
                id="asset-data.headline-benefits"
                defaultMessage="Your <mark>benefits</mark>"
                values={{ mark: (text) => <mark>{text}</mark> }}
              />
            </h2>

            <div className="mt-4 grid grid-cols-1 gap-y-10 gap-x-8 lg:grid-cols-3">
              <div className="sm:flex lg:block">
                <div className="sm:flex-shrink-0">
                  <img className="w-16 h-16" src="/symbols/loop.png" alt="" />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                  <h3 className="text-sm font-medium text-gray-900">
                    <FormattedMessage id="asset-data.section-2-headline-1" defaultMessage="Up-to-date and Actionable Information" />
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    <FormattedMessage id="asset-data.section-2-text-1" defaultMessage="Say goodbye to heterogeneous, outdated and unreliable data." />
                  </p>
                </div>
              </div>
              <div className="sm:flex lg:block">
                <div className="sm:flex-shrink-0">
                  <img className="w-16 h-16" src="/symbols/earth_longlat.png" alt="" />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                  <h3 className="text-sm font-medium text-gray-900">
                    <FormattedMessage id="asset-data.section-2-headline-2" defaultMessage="A scalable solution" />
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    <FormattedMessage id="asset-data.section-2-text-2" defaultMessage="Get the information you need, beyond international borders." />
                  </p>
                </div>
              </div>
              <div className="sm:flex lg:block">
                <div className="sm:flex-shrink-0">
                  <img className="w-16 h-16" src="/symbols/screen.png" alt="" />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                  <h3 className="text-sm font-medium text-gray-900">
                    <FormattedMessage id="asset-data.section-2-headline-3" defaultMessage="Property Information at your Fingertips" />
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    <FormattedMessage id="asset-data.section-2-text-3" defaultMessage="Get the information you need conveniently from your desk." />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white max-w-2xl lg:max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8 xl:mb-32">
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <div>
              <h2 className="text-3xl font-extrabold text-gray-900">
                <FormattedMessage
                  id="asset-data.use-cases-headline"
                  defaultMessage="<mark>Use-cases</mark>"
                  values={{ mark: (text) => <mark>{text}</mark> }}
                />
              </h2>
            </div>
            <div className="mt-12 lg:mt-0 lg:col-span-2">
              <dl className="space-y-12">
                <div>
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    <FormattedMessage
                      id="asset-data.use-case-1"
                      defaultMessage="Autocomplete Building Information of Real Estate Investment Banking Portfolios"
                    />
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    <FormattedMessage
                      id="asset-data.use-case-1-text"
                      defaultMessage="Real estate investment banks have a large number of properties in their portfolio. Maintaining up-to-date building metric databases is important, yet a challenge, especially when properties are spread across different countries. With our geospatial technology we can help to make your data consistent and comparable!"
                    />
                  </dd>
                </div>
                <div>
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    <FormattedMessage id="asset-data.use-case-2" defaultMessage="Improve the Accuracy of Real Estate Valuation" />
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    <FormattedMessage
                      id="asset-data.use-case-2-text"
                      defaultMessage="The more information you have about a property, the better you can estimate its value. With our built-up checker service we enable real estate valuation companies to determine the built-up status of individual properties over time in order to reduce the range of their property value estimates."
                    />
                  </dd>
                </div>
                <div>
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    <FormattedMessage id="asset-data.use-case-3" defaultMessage="Property-level Flood Hazard Rating in Banking" />
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    <FormattedMessage
                      id="asset-data.use-case-3-text"
                      defaultMessage="More and more banks take flood risks into account when making loans and assessing a home’s value. In this context, actionable flood risk data are essential in order to make a good decision that is in the interest of all stakeholders involved."
                    />
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
        <div className="max-w-screen-2xl mx-auto my-20">
          <ContactUsBar intl={intl} />
        </div>
      </main>
    </Layout>
  );
}
