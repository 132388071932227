import { graphql, useStaticQuery } from "gatsby";
import { BgImage } from "gbimage-bridge";
import React from "react";
import { ExternalLinkIcon } from "@heroicons/react/solid";
import { Link } from "gatsby-plugin-react-intl";

export default function ContactUsBar({ intl }) {
  const { componentImage } = useStaticQuery(
    graphql`
      query {
        componentImage: file(relativePath: { eq: "caspian-sea.jpg" }) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 50, aspectRatio: 1, width: 675)
          }
        }
      }
    `
  );

  return (
    <div className="relative bg-gray-800">
      <div className="h-56 bg-uc-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <BgImage image={componentImage.childImageSharp.gatsbyImageData} className="h-full">
          <div className="h-full" style={{ background: "linear-gradient(135deg, #010F13AB 40%, #00000000 100%)" }}></div>
        </BgImage>
      </div>
      <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        <div className="md:ml-auto md:w-1/2 md:pl-10">
          <h2 className="text-base font-semibold uppercase tracking-wider text-gray-300">
            {intl.formatMessage({ id: "contact-us-bar.contact", defaultMessage: "Contact ubicube" })}
          </h2>
          <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">
            {intl.formatMessage({ id: "contact-us-bar.get-in-touch", defaultMessage: "Get in touch with us!" })}
          </p>
          <p className="mt-3 text-lg text-gray-300">
            {intl.formatMessage({
              id: "contact-us-bar.text",
              defaultMessage: "Are you interested in becoming an early adopter, starting a collaboration or exchanging experiences?",
            })}
          </p>
          <div className="mt-8">
            <div className="inline-flex rounded-md shadow">
              <Link
                to="/contact-us"
                className="inline-flex items-center justify-center mr-3 mb-3 px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
              >
                {intl.formatMessage({ id: "contact-us-bar.contact-cta", defaultMessage: "Contact Us" })}
                <ExternalLinkIcon className="-mr-1 ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
              </Link>
            </div>
            {/* <div className="inline-flex rounded-md shadow">
              <a
                href="https://calendly.com/christian-oswald/ubicube-intro"
                className="inline-flex items-center justify-center mr-3 mb-3 px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
              >
                {intl.formatMessage({ id: "contact-us-bar.bookacall-cta", defaultMessage: "Book a call" })}
                <ExternalLinkIcon className="-mr-1 ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
